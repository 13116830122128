
html {
  background-color: #fffbf7;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Helvetica';
  display: flex;
  justify-content: center;
  background-color: #fffbf7;
  font-size: 12px;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

#root {
  /* padding: 70px 0 0 0; */
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  box-sizing: border-box;
}

.home {
  flex: 1;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
  box-sizing: border-box;
}

.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 1em;
  width: 1em;
  border: 0.15em black solid;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .radio {
  background-color: rgba(0,0,0,0.075);
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .radio {
  background-color: black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .radio:after {
  display: block;
}

/* Create a custom checkbox */
.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 1em;
  width: 1em;
  border: 0.15em black solid;
  border-radius: 5px;
}
#blueCheck {
  border: 0.15em #3030ef solid;
  border-radius: 50%;
}
#redCheck {
  border: 0.15em #ef3030 solid;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkbox {
  background-color: rgba(0,0,0,0.075);
}

/* Create the checkbox/indicator (hidden when not checked) */
.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkbox when checked */
.container input:checked ~ .checkbox:after {
  display: block;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkbox {
  background-color: black;
}
.container input:checked ~ #blueCheck {
  background-color: #3030ef;
}
.container input:checked ~ #redCheck {
  background-color: #ef3030;
}

.filter-input {
  width: 50px;
  height: 12px;
  margin: 0 2px 0 2px;
  font-size: 12px;
}

.unit {
  width: 35px;
  margin: 0 0 0 0;
  text-align: right;
}

p {
  margin: 10px 0;
}

.map {
  flex: 0 1 calc(60% - 30px);
  min-height: 300px;
  height: calc((100vw - 300px)*0.5);
  margin: 0 10px 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 5px 5px 25px rgba(0,0,0,0.1);
  box-sizing: border-box;
  background-color: #aad3e0;
}

.ol-viewport {
  border-radius: 10px;
}

.location-loading {
  flex: 0 1 calc(40% - 30px);
  margin: 0 10px 20px 10px;
  min-height: 300px;
  height: calc((100vw - 300px)*0.5);
  width: calc(100% - 40px);
}

.locations {
  box-shadow: 5px 5px 25px rgba(0,0,0,0.1);
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.locations-cont {
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Edge, and Safari */
.hidden-scrollbar::-webkit-scrollbar, .body::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar, body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.location {
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: space-between;
  transition: opacity ease 0.2s;
  overflow-x: hidden;
  width: 100%;
}

.location:hover {
  cursor: pointer;
  opacity: 0.6;
}

.arrow {
  transform: rotate(-90deg) scale(0.65);
  margin: 0;
}

.arrow-small {
  transform: rotate(-90deg) scale(0.55);
  margin: 0;
}

.rotatable {
  transition: transform 0.2s ease;
}

.rotated {
  transform: rotate(0deg) scale(0.65);
}

.rotated-small {
  transform: rotate(0deg) scale(0.55);
}

.filter-title:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity ease 0.2s;
}

.filter-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
}

.map:hover {
  cursor: grab;
}

.map:active {
  cursor: grabbing;
}

.pan:hover {
  cursor: grab;
}

.pan:active {
  cursor: grabbing;
}

.cl-button {
  width: 100%;
}

button {
  height: 30px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 10px 15px 10px 15px;
}

button:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,0.75);
  transition: background-color ease-in 0.3s;
}

.popup {
  background-color: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
}

.popup-cont {
  display: flex;
  flex-direction: column;
  transform: translateY(5px);
  display: none;
}

#popup-content {
  color: black;
  background-color: white;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
  transform: translate(20px, -70px);
  box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
}

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fffbf7;
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 70px; /* Height of the header + marginbtm */
  width: 100%;
  box-sizing: border-box;
}

#berryprice {
  width: 79.34px;
}

#manageLocations:hover, #berryprice:hover, #articles:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity ease 0.2s;
}

.update {
  width: 100vw;
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  margin: 0 0 100px 0;
  flex: 1;
}

.card-cont {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

#manageLocsCard {
  width: calc(100% - 340px);
  min-width: 280px;
  box-sizing: border-box;
}

#membersCard {
  min-width: 280px;
  width: 320px;
  max-height: 377.08px;
  box-sizing: border-box;
}

.card {
  box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.update-cont {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.manage-location {
  box-sizing: border-box;
  margin: 0 0px 0px 0;
  padding: 15px;
  width: 300px;
  position: relative;
  border: 0.5px solid rgba(0,0,0,0.3);
  border-radius: 10px;
}

.hamburger-menu:hover {
  cursor: pointer;
}

.menu {
  position: fixed;
  background-color: #fffbf7;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 0 10px 0;
  margin-bottom: 40;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
  width: 100vw;
  top: 50px;
}

@media (min-width: 768px) {
  .hamburger-menu {
    display: none;
  }
  .menu {
    display: none;
  }
  #user {
    display: flex;
  }
}

@media (max-width: 767px) {
  #manageLocations {
    display: none;
  }
  .widescreen {
    display: none;
  }
  #user {
    display: none;
  }
  #membersCard {
    width: 100%;
  }
  #manageLocsCard {
    width: 100%;
  }
}

@media (max-width: 364px) {
  .signed-out-small {
    display: none;
  }
}

@media (max-width: 1000px) {
  .map {
    flex: 1 1 calc(100% - 40px);
  }
  .description {
    width: calc(100% - 40px);
    left: calc((100% - 40px) * 0.5 + 20px);
  }
  .location-loading {
    flex: 1 1 calc(100% - 40px);
  }
}

@media (min-width: 1000px) {
  .description {
    width: calc(60% - 50px);
    left: calc((60% - 50px) * 0.5 + 30px);
  }
}

.article {
  padding: 0 20px 0 20px;
  width: 100%;
  max-width: 700px;
}

.article > * {
  text-align: justify;
}

.article > h1, .article > h2, .article > h3, .article > a {
  text-align: left;
}

.article-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.loading-container {
  display: flex;
  padding: 0 20px 0 20px;
  flex: 1;
}

.inactive {
  opacity: 0.5;
}

a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.2s ease;
  margin: 0px 0 5px 0;
}

a:hover {
  opacity: 0.7;
}

.real-link {
  color: #3030ef;
}

#fake-padding {
  width: 100%;
  height: 83px;
}

.custom-marker {
  background-color: white;
  padding: 4px;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  position: relative;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
}

.popup-overlay {
  position: relative;
  z-index: 100;
}

.berry-symbol {
  border-radius: 50%;
  width: 0.8em;
  height: 0.8em;
  margin: 2% 3px 2px 0;
}

.blue {
  background-color: #3030ef;
}

.red {
  background-color: #ef3030;
}

.marker-text {
  margin: 0;
}

.horizontal {
  display: flex;
  align-items: baseline;
}

.marker-cont {
  position: relative;
  display: inline-block;
  transform: translateY(-5px);
}

.marker-cont:hover {
  cursor: pointer;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%; /* Position it at the bottom of the rectangle */
}

.flip {
  transform: rotate(180deg) translateX(50%);
}

h1 {
  font-size: 48px;
}

h2 {
  margin: 0 0 15px 0;
}

.create-location {
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 100px;
}

.line {
  background-color: rgba(0,0,0,0.3);
  height: 0.5px;
  margin: 10px 0 10px 0;
}

.create-input {
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 10px 0;
}

.language {
  box-sizing: border-box;
  margin: 0 0 10px 0;
  background-color: #fffbf7;
}

.join {
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 100px;
  flex: 1;
}

/* General reset for select elements */
select, input[type="text"], input[type="tel"], input[type="email"], input[type="number"], input[type="time"] {
  -webkit-appearance: none; /* Removes default styling in Safari/Chrome */
  -moz-appearance: none; /* Removes default styling in Firefox */
  appearance: none; /* Removes default styling in modern browsers */
  background: none; /* Removes default background */
  border: none; /* Removes default border */
  padding: 0; /* Removes default padding */
  font: inherit; /* Ensures font consistency */
  color: inherit; /* Ensures text color consistency */
  outline: none;
}

/* Additional styles to make it look like a basic dropdown */
select, input[type="text"], input[type="tel"], input[type="email"], input[type="number"], input[type="time"] {
  border: 1px solid #ccc; /* Adds a border */
  background-color: #fff; /* Sets background color */
  padding: 0.5em;
  border-radius: 4px; /* Rounds the corners */
}

input[type="time"] {
  width: 75px;
  height: 20px;
}

.time-cont p {
  height: 20px;
}

.time {
  height: 20px;
  align-items: center;
  margin: 0 0 10px 0;
}

.time-cont {
  height: 20px;
  align-items: center;
}

select {
  cursor: pointer; /* Changes the cursor to pointer */
}

.flag {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5em;
  transition: opacity 0.2s ease;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .flag {
  opacity: 0.5;
}

/* When the flag button is checked, add a blue background */
.container input:checked ~ .flag {
  border-bottom: 2px solid black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.flag:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .flag:after {
  display: block;
}

.bottom {
  position: absolute;
  bottom: 0;
  padding: 0 0 0px 0;
  background-color: #fffbf7;
  width: calc(100% - 40px);
}

.no-button {
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.no-button:hover {
  opacity: 0.5;
}

.locationMap {
  position: relative;
  top: 50%;
  left: 50%;
  justify-content: center;
  transform: translateX(-50%);
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
  pointer-events: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  pointer-events: all;
  display: flex;
  pointer-events: none;
}

.highlight {
  position: absolute;
  pointer-events: none;
  background: transparent;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  transform: translate(-10px, -10px);
}

.description {
  position: absolute;
  z-index: 1004;
  box-sizing: border-box;
  pointer-events: all;
  top: 80px;
  text-align: center;
  transform: translateX(-50%);
  max-width: 320px;
}

.text {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: 0 0 10px 0;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
}

.prev-button, .next-button, .steps {
  background: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  z-index: 1005;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
}

.prev-button, .next-button {
  cursor: pointer;
  pointer-events: all;
}

.prev-button:disabled, .next-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.next-button {
  position: absolute;
  left: 100%;
  transform: translateX(-100%);
}

.steps {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

footer {
  height: 100px;
  box-shadow: 5px -5px 15px rgba(0,0,0,0.1);
  background-color: #fffbf7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.footer-cont {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 20px 0 20px;
  width: 100%;
}

.privacy-terms {
  display: flex;
  flex-direction: column;
  margin-right: 60px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (max-width: 354px) {
  .contact-info {
    align-items: flex-start;
  }
}

.cl-organizationPreviewAvatarContainer {
  display: none;
}

.cl-organizationPreviewSecondaryIdentifier {
  display: none;
}

.cl-internal-itqysx {
  display: none;
}

.cl-button__createOrganization {
  display: none;
}

.cl-internal-81ln96 {
  display: none;
}

.hover {
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.hover:hover {
  opacity: 0.5;
}
